import React from "react";
import PropTypes from "prop-types";
import * as coreTheme from "@bit/cpinnix.verious.theme";

function createAppearance({ wide, small, theme }) {
  return {
    wrapper: {
      className: `
        focus:outline-none
        ${
          small
            ? `
              py-2
              px-5
            `
            : `
              py-3
              px-6
            `
        }
        rounded-full
        border-2
        ${wide ? "w-full" : ""}
      `,
      style: {
        backgroundColor: theme?.backgroundColor,
        borderColor: theme?.backgroundColor,
        color: theme?.color,
      },
    },
  };
}

export default function SolidCircularButton(props) {
  const { children } = props;
  const appearance = createAppearance(props);
  return <button {...appearance.wrapper}>{children}</button>;
}

SolidCircularButton.propsTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  wide: PropTypes.bool,
  theme: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};
