import React from "react";
import "./index.css";
import Helmet from "react-helmet";

export default function DesignSystem() {
  return (
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap"
        rel="stylesheet"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;700;900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}
