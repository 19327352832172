export const logo = {
  primary: "#f27d77",
};

export const heading = {
  primary: "#1d2e47",
};

export const button = {
  primary: "#1d2e47",
};

export const font = {
  primary: "Source Serif Pro",
};
