import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const appear = keyframes`
  0% {
    opacity: 0;
  }

  1% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    transform: translateY(100vh);
  }
`;

const disappear = keyframes`
  0% {
    transform: translateY(100vh);
  }

  99% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export default styled.div`
  display: block;
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: scroll;
  animation-name: ${(props) =>
    props.initiated && (props.open ? appear : disappear)};
  animation-duration: 400ms;
  animation-timing-function: ${(props) =>
    props.open
      ? "cubic-bezier(0.0, 0.0, 0.2, 1)"
      : "cubic-bezier(0.4, 0.0, 1, 1)"};
  animation-fill-mode: forwards;
  background-color: ${(props) => props.theme.wrapper.backgroundColor};
  z-index: 20;
`;

export function usePanel() {
  const [open, updateOpen] = useState(false);
  const [initiated, updateInitiated] = useState(false);

  function toggle() {
    updateOpen(!open);
    updateInitiated(true);
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  return {
    toggle,
    open,
    initiated,
  };
}
