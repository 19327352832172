import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

export default function SearchEngineOptimization({
  title,
  description,
  image,
  article,
}) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    titleTemplate,
    defaultTitle,
    siteUrl,
    // twitterUsername,
  } = site.siteMetadata;

  const url = `${siteUrl}${pathname}`;
  const fullTitle = title || defaultTitle;
  const protocol =
    typeof window !== "undefined" ? window.location.protocol : "http:";

  return (
    <Helmet title={fullTitle} titleTemplate={titleTemplate}>
      <meta name="description" content={description} />
      <meta name="image" content={`${protocol}${image}`} />

      {url && <meta property="og:url" content={url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {fullTitle && <meta property="og:title" content={fullTitle} />}

      {description && <meta property="og:description" content={description} />}

      {image && <meta property="og:image" content={`${protocol}${image}`} />}

      <meta name="twitter:card" content="summary_large_image" />

      {/* {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />} */}
    </Helmet>
  );
}

SearchEngineOptimization.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SearchEngineOptimization.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SearchEngineOptimization {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;

// const query = graphql`
//   query SearchEngineOptimization {
//     site {
//       siteMetadata {
//         defaultTitle: title
//         titleTemplate
//         defaultDescription: description
//         siteUrl: url
//         defaultImage: image
//         twitterUsername
//       }
//     }
//   }
// `;
