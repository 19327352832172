import React, { useState } from "react";
import * as coreTheme from "@bit/cpinnix.verious.theme";

function createAppearance({ theme }) {
  return {
    dropdown: {
      className: `
        focus:outline-none
        h-12
        z-10
      `,
    },
    button: {
      className: `
        focus:outline-none
        h-12
        inline-grid
        items-center
        relative
      `,
    },
    content: {
      className: `
        absolute
        py-2
        shadow-md
        rounded
        overflow-hidden
      `,
      style: {
        backgroundColor: theme.content.backgroundColor,
      },
    },
    link: {
      className: `
        block
        h-12
        grid
        items-center
        px-6
      `,
    },
  };
}

export default function HoverDropdown(props) {
  const { label, links } = props;
  const [contentVisible, updateContentVisible] = useState(false);
  const appearance = createAppearance(props);

  return (
    <div
      {...appearance.dropdown}
      role="button"
      tabIndex={0}
      onMouseEnter={() => {
        updateContentVisible(true);
      }}
      onMouseLeave={() => {
        updateContentVisible(false);
      }}
    >
      <div {...appearance.button}>{label}</div>
      {contentVisible && (
        <div {...appearance.content}>
          {links.map(({ renderLink }, index) =>
            renderLink({ ...appearance.link, key: index })
          )}
        </div>
      )}
    </div>
  );
}

HoverDropdown.defaultProps = {
  theme: {
    content: { backgroundColor: coreTheme.white },
  },
};
