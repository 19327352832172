import PropTypes from "prop-types";
import React from "react";
import * as coreTheme from "@bit/cpinnix.verious.theme";

export default function Close({ open = false, theme }) {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        position: "relative",
      }}
    >
      <div
        style={{
          width: 24,
          height: 2,
          position: "absolute",
          top: 8,
          transform: open ? "rotateZ(135deg) translate(2px, -2px)" : "none",
          transition: "transform 300ms linear, background-color 300ms linear",
          backgroundColor: open ? theme.open.color : theme.closed.color,
        }}
      />
      <div
        style={{
          width: 24,
          height: 2,
          position: "absolute",
          top: 14,
          transform: open ? "rotateZ(45deg) translate(-2px, -2px)" : "none",
          transition: "transform 150ms linear, background-color 300ms linear",
          backgroundColor: open ? theme.open.color : theme.closed.color,
        }}
      />
    </div>
  );
}

Close.defaultProps = {
  theme: {
    open: {
      color: coreTheme.white,
    },
    closed: {
      color: coreTheme.black,
    },
  },
};

Close.propTypes = {
  open: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    open: PropTypes.shape({
      color: PropTypes.string.isRequired,
    }).isRequired,
    closed: {
      color: PropTypes.string.isRequired,
    }.isRequired,
  }),
};
