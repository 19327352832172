import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import startsWith from "lodash/startsWith";
import DesignSystem from "components/DesignSystem";
import SearchEngineOptimization from "components/SearchEngineOptimization";
import GatsbyLink from "gatsby-link";
import GatsbyImage from "gatsby-image";
import FacebookIcon from "@bit/cpinnix.verious.facebook-icon";
import InstagramIcon from "@bit/cpinnix.verious.instagram-icon";
import SolidCircularButton from "components/SolidCircularButton";
import HoverDropdown from "components/HoverDropdown";
import HamburgerIcon from "components/HamburgerIcon";
import Panel, { usePanel } from "components/Panel";
import LogoType from "components/LogoType";
import log from "utils/log";
import * as coreTheme from "@bit/cpinnix.verious.theme";
import * as siteTheme from "theme";

function filterLinksByPath(path, links) {
  return links.filter(
    (link) =>
      startsWith(link.path, path) && link.path !== path && !link.featured
  );
}

const blankLink = ({ label }) => ({ className, style, key }) => (
  <div className={className} style={style} key={key}>
    {label}
  </div>
);

const internalLink = ({ href, label }) => ({ className, style, key }) => {
  return (
    <GatsbyLink className={className} style={style} key={key} to={href}>
      {label}
    </GatsbyLink>
  );
};

const externalLink = ({ href, label }) => ({ className, style, key }) => (
  <a
    className={className}
    style={style}
    key={key}
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    {label}
  </a>
);

const externalCTA = ({ href, label }) => ({ className, style, key }) => (
  <a
    className={className}
    style={style}
    key={key}
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    <SolidCircularButton
      small
      theme={{
        backgroundColor: siteTheme.button.primary,
        color: coreTheme.white,
      }}
    >
      {label}
    </SolidCircularButton>
  </a>
);

const shopLinks = [
  {
    path: "/shop",
    renderLink: externalLink({
      label: "Shop",
      href: "https://nanashaven.threadless.com/",
    }),
  },
];

const adoptLinks = [
  {
    path: "/adopt",
    renderLink: blankLink({
      label: "Adopt",
    }),
  },
  {
    path: "/adopt/dogs",
    renderLink: internalLink({
      label: "Adoptable Dogs",
      href: "/adopt/dogs",
    }),
  },
  {
    path: "/adopt/happy-tails",
    renderLink: internalLink({
      label: "Happy Tails",
      href: "/adopt/happy-tails",
    }),
  },
];

const fosterLinks = [
  {
    path: "/foster",
    renderLink: blankLink({
      label: "Foster",
    }),
  },
  {
    path: "/foster/program",
    renderLink: internalLink({
      label: "Our Program",
      href: "/foster",
    }),
  },
];

const aboutLinks = [
  {
    path: "/about",
    renderLink: blankLink({
      label: "About",
    }),
  },
  {
    path: "/about/mission",
    renderLink: internalLink({
      label: "Mission",
      href: "/about/mission",
    }),
  },
  {
    path: "/about/process",
    renderLink: internalLink({
      label: "Process",
      href: "/about/process",
    }),
  },
  {
    path: "/about/faqs",
    renderLink: internalLink({
      label: "FAQs",
      href: "/about/faqs",
    }),
  },
  {
    path: "/about/partners",
    renderLink: internalLink({
      label: "Partners",
      href: "/about/partners",
    }),
  },
  {
    path: "/about/nana",
    renderLink: internalLink({
      label: "Nana The Warrior",
      href: "/about/nana",
    }),
  },
];

const donateLinks = [
  {
    path: "/donate",
    featured: true,
    callToAction: true,
    renderLink: externalCTA({
      label: "Donate",
      href:
        "https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=A9P7M5NSV5U8G",
    }),
  },
];

const headerLinks = [
  ...aboutLinks,
  ...adoptLinks,
  ...fosterLinks,
  ...shopLinks,
  ...donateLinks,
];

const footerLinks = [
  ...adoptLinks,
  ...aboutLinks,
  ...fosterLinks,
  ...shopLinks,
  {
    social: "facebook",
    renderLink: externalLink({
      label: <FacebookIcon size={40} color="black" />,
      href: "https://www.facebook.com/nanashaven20/",
    }),
  },
  {
    social: "instagram",
    renderLink: externalLink({
      label: <InstagramIcon size={40} color="black" />,
      href: "https://www.instagram.com/nanas_haven_/",
    }),
  },
];

const query = graphql`
  query {
    partners: allContentfulPartner {
      edges {
        node {
          id
          cta
          logo {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

function present(data) {
  const presentation = {
    partners: data?.partners?.edges.map((edge) => ({
      ...edge.node,
      logo: edge.node.logo[0],
    })),
  };

  log("[PAGE_WRAPPER] presentation", presentation);

  return presentation;
}

export default function PageWrapper(props) {
  const { children, seo } = props;

  const footer = {
    topLevelLinks: footerLinks
      .filter((link) => !link.social)
      .filter((link) => !link.featured)
      .filter((link) => !link.callToAction)
      .filter((link) => link.path.split("/").length === 2),
    // featuredLinks: footerLinks
    //   .filter((link) => !link.callToAction)
    //   .filter((link) => link.featured),
    socialLinks: footerLinks
      .filter((link) => link.social)
      .filter((link) =>
        ["facebook", "instagram", "twitter"].includes(link.social)
      ),
  };

  const header = {
    featuredLinks: headerLinks.filter((link) => link.featured),
    barLinks: headerLinks.filter((link) => {
      return link.path.split("/").length === 2 || link.featured;
    }),
    sectionLinks: headerLinks.filter((link) => {
      return link.path.split("/").length === 2 && !link.featured;
    }),
  };

  const panel = usePanel();

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const presentation = present(data);

        const { partners } = presentation;

        return (
          <>
            <DesignSystem />
            <SearchEngineOptimization {...seo} />
            {/* Header ---> */}
            <div className="bg-white">
              <div className="container mx-auto px-8 md:px-16">
                <div className="flex items-center h-24">
                  <GatsbyLink to="/">
                    <LogoType
                      width={80}
                      theme={{
                        color: "rgba(244, 114, 182, 100)",
                      }}
                    />
                  </GatsbyLink>
                  <div className="flex-1" />
                  <div className="hidden md:flex md:items-center md:h-24 space-x-8">
                    {header.barLinks.map(({ path, label, renderLink }) => {
                      const childLinks = headerLinks.filter((link) => {
                        return link.path !== path && link.path.includes(path);
                      });

                      return childLinks.length > 0 ? (
                        <HoverDropdown
                          key={`${path} ${label}`}
                          label={renderLink({
                            className:
                              "focus:outline-none relative h-12 inline-grid items-center",
                          })}
                          links={childLinks}
                        />
                      ) : (
                        renderLink({
                          className:
                            "focus:outline-none relative h-12 inline-grid items-center",
                          key: `${path} ${label}`,
                        })
                      );
                    })}
                  </div>
                  <button
                    className="focus:outline-none pointer-events-auto z-30 p-3 -mr-3 md:hidden"
                    type="button"
                    onClick={panel.toggle}
                  >
                    <HamburgerIcon
                      open={panel.open}
                      theme={{
                        open: {
                          color: coreTheme.black,
                        },
                        closed: {
                          color: coreTheme.black,
                        },
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
            <Panel
              theme={{
                wrapper: {
                  backgroundColor: coreTheme.gray[100],
                },
                sectionLink: { color: coreTheme.gray[500] },
              }}
              {...panel}
            >
              <div>
                <div className="pt-24 pl-8 pr-12 pb-12">
                  <div className="mb-8">
                    {header.featuredLinks.map((link) => (
                      <div key={`${link.path} ${link.label}`}>
                        {link.renderLink({ className: "py-1 px-2 text-black" })}
                      </div>
                    ))}
                  </div>
                  {header.sectionLinks.map((link) => {
                    const childLinks = headerLinks.filter((childLink) => {
                      return (
                        childLink.path !== link.path &&
                        startsWith(childLink.path, link.path)
                      );
                    });

                    return (
                      <div className="mb-8" key={`${link.path} section`}>
                        <div key={`${link.path} ${link.label}`}>
                          {link.renderLink({
                            className: `py-1 px-2 block ${
                              childLinks.length > 0
                                ? "text-gray-400"
                                : "text-black"
                            }`,
                          })}
                        </div>
                        {childLinks.map((childLink) => (
                          <div key={`${childLink.path} ${childLink.label}`}>
                            {childLink.renderLink({
                              className: "py-1 px-2 block",
                            })}
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Panel>
            {/* <--- Header */}
            <div>{children}</div>
            {/* Footer ---> */}
            <div className="bg-gray-100 py-32">
              <div className="container mx-auto px-8 md:px-16">
                <div className="text-center grid gap-4 sm:flex sm:items-center sm:justify-end">
                  <div className="mr-4">Be a forever home.</div>
                  <a
                    href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=A9P7M5NSV5U8G"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="py-3 px-6 rounded-full border-2 border-custom-slate bg-custom-slate text-white">
                      Make a Donation Today
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="pt-24 pb-4">
              <div className="container mx-auto px-8 md:px-16">
                <div className="lg:grid lg:gap-8 lg:grid-cols-12">
                  <div className="lg:col-span-2 mb-8 lg:mb-8">
                    <GatsbyLink to="/">
                      <LogoType
                        width={80}
                        theme={{
                          color: "rgba(244, 114, 182, 100)",
                        }}
                      />
                    </GatsbyLink>
                  </div>
                  {footer.topLevelLinks.map(({ path, label, renderLink }) => {
                    const filteredLinks = filterLinksByPath(path, footerLinks);

                    return (
                      <ul
                        className="lg:col-span-2 mb-8 lg:mb-0"
                        key={`${path} ${label}`}
                      >
                        <li>
                          {renderLink({
                            className: `${
                              filteredLinks.length > 0
                                ? "text-gray-400"
                                : "text-black"
                            } block my-2`,
                          })}
                        </li>
                        {filterLinksByPath(path, footerLinks).map((link) => (
                          <li key={`${link.path} ${link.label}`}>
                            {link.renderLink({
                              className: "text-black block my-2",
                            })}
                          </li>
                        ))}
                      </ul>
                    );
                  })}
                  <div className="lg:col-span-2">
                    <div className="text-gray-400 mt-2 mb-4">Our Partners</div>
                    <div className="flex">
                      {partners.map((partner, index) => (
                        <a
                          key={index}
                          href={partner.cta}
                          target="_blank"
                          rel="noreferrer"
                          className="block h-24 w-24 mr-2 rounded-full border border-gray-200 overflow-hidden"
                        >
                          <GatsbyImage fluid={partner.logo.fluid} />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mx-auto px-8 md:px-16 mt-8">
                <div className="lg:grid lg:gap-8 lg:grid-cols-12">
                  <div className="flex space-x-2 mb-8 lg:mb-0">
                    {footer.socialLinks.length > 0 &&
                      footer.socialLinks.map((link, index) =>
                        link.renderLink({ key: index })
                      )}
                  </div>
                  <div className="lg:col-start-11 lg:col-span-2">
                    <a href="mailto:rescue@nanashaven.org">
                      rescue@nanashaven.org
                    </a>
                  </div>
                </div>
              </div>
              <div className="container mx-auto px-8 md:px-16 mt-24">
                <div className="text-gray-400">
                  Copyright © 2020 Nana's Haven. All Rights Reserved. ~{" "}
                  <GatsbyLink to="/credits">Credits</GatsbyLink>
                </div>
              </div>
            </div>
            {/* <--- Footer */}
          </>
        );
      }}
    />
  );
}

PageWrapper.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};
